<template>
    <div class="main-container" v-if="!loading">
        <div class="header-container">
            <div class="left-part-header">
                <ResidenceBreadcrumb :path="path" />
                <h1 class="title">
                    {{ $tc("exportFobs.fobsTitle") }}
                </h1>
                <p class="fobs-number">
                    {{
                        $tc("exportFobs.fobsNumber", {
                            count: fobsNumber,
                        })
                    }}
                    <span v-if="fobsNumber > 1">
                        ({{
                            $tc("exportFobs.fobsCount", {
                                minCount: minFobNumber,
                                maxCount: maxFobNumber,
                            })
                        }})
                    </span>
                </p>
            </div>
            <div class="right-part-header">
                <BasicSearch
                    class="search"
                    :searchPlaceholder="$t('exportFobs.search')"
                    @search="setSearch"
                />
            </div>
        </div>
        <div class="filter-export-container">
            <div class="filters-wrapper">
                <p>{{ $t("exportFobs.fobsLastUse") }}</p>
                <BasicSelect
                    class="filters"
                    name="fobsLastUse"
                    :options="filtersOptions"
                    @change="setFilter($event.target.value)"
                />
            </div>
            <BasicButton
                color="blue-neutral"
                class="export-button"
                @click="exportData()"
                :disabled="loadingArray || noDataSearch || noData"
            >
                <i class="far fa-arrow-alt-circle-down" />
                {{ $t("entityDetails.actions.export") }}
            </BasicButton>
        </div>
        <div class="body-container">
            <Array
                :content="whichContent()"
                @deleteItem="deleteItem($event)"
                @seeMore="seeMore($event)"
                v-if="!loadingArray && !noDataSearch && !noData"
            />
            <div
                class="no-data"
                v-else-if="noDataSearch && !noData && !loadingArray"
            >
                <p>{{ $t("exportFobs.noDataSearch") }}</p>
            </div>
            <div
                class="no-data"
                v-else-if="noData && !noDataSearch && !loadingArray"
            >
                <p>{{ $t("exportFobs.noData") }}</p>
            </div>
            <div class="loader-wrapper" v-else-if="loadingArray">
                <Loader />
            </div>
        </div>
        <div class="footer-container">
            <Pagination
                :page="page"
                :pagesCount="pagesCount"
                @new-current-page="setPage"
            />
        </div>
    </div>
    <div v-else class="loader-wrapper">
        <Loader />
    </div>
</template>

<script>
import { getResidenceSummaryById } from "@/services/intratone/residence"
import { getStairwellSummaryById } from "@/services/intratone/stairwell"
import {
    getUsedAndUnusedFobs,
    deleteFob,
    exportFobs,
} from "@/services/intratone/fob"

import ResidenceBreadcrumb from "@/components/entities/ResidenceBreadcrumb.vue"
import Array from "@/components/basic/Array.vue"
import BasicSearch from "@/components/basic/BasicSearch.vue"
import Loader from "@/components/basic/Loader.vue"
import Pagination from "@/components/basic/Pagination.vue"
import BasicButton from "@/components/basic/BasicButton.vue"
import BasicSelect from "@/components/basic/BasicSelect.vue"

export default {
    name: "ResidenceExportFobsView",
    // mixins: [modal],
    components: {
        ResidenceBreadcrumb,
        Loader,
        BasicSearch,
        Array,
        Pagination,
        BasicButton,
        BasicSelect,
    },
    data() {
        return {
            path: {},
            idRes: this.$store.getters["account/getResidenceId"],
            noData: false,
            noDataSearch: false,
            loading: false,
            loadingArray: false,
            fobsObjectResidenceComplete: {
                header: [
                    {
                        name: this.$t("global.entities.building"),
                        show: true,
                        column_name: "building_name",
                    },
                    {
                        name: this.$t("global.entities.stairwell"),
                        show: true,
                    },
                    //Situation when whe have text and icon on same table cell
                    {
                        name: this.$t("global.entities.apartment"),
                        show: true,
                    },

                    {
                        name: this.$t("exportFobs.fobsSerial"),
                        show: true,
                    },

                    {
                        name: this.$t("exportFobs.fobsLastUse"),
                        show: true,
                    },
                    {
                        name: "",
                        size: "auto",
                        class: "fa fa-trash",
                        action: "delete",
                        show: false,
                        style: "display: flex; align-items: center; font-size: 18px;",
                    },
                ],
                content: [],
            },
            fobsObjectResidenceBasic: {
                header: [
                    {
                        name: this.$t("exportFobs.fobsSerial"),
                        show: true,
                    },

                    {
                        name: this.$t("exportFobs.fobsLastUse"),
                        show: true,
                    },
                    {
                        name: "",
                        size: "auto",
                        class: "fa fa-trash",
                        action: "delete",
                        show: false,
                        style: "display: flex; align-items: center; font-size: 18px",
                    },
                ],
                content: [],
            },
            fobsObjectStairwell: {
                header: [
                    {
                        name: this.$t("global.entities.apartment"),
                        show: true,
                    },
                    {
                        name: this.$t("exportFobs.fobsSerial"),
                        show: true,
                    },

                    {
                        name: this.$t("exportFobs.fobsLastUse"),
                        show: true,
                    },
                    {
                        name: "",
                        size: "auto",
                        class: "fa fa-trash",
                        action: "delete",
                        show: false,
                        style: "display: flex; align-items: center; font-size: 18px",
                    },
                ],
                content: [],
            },
            filtersOptions: [
                {
                    name: this.$t("exportFobs.fobsFilterDefault"),
                    value: "",
                },
                {
                    name: this.$t("exportFobs.fobsFilterOneWeek"),
                    value: "P1W",
                },
                {
                    name: this.$t("exportFobs.fobsFilterTwoWeeks"),
                    value: "P2W",
                },
                {
                    name: this.$t("exportFobs.fobsFilterOneMonth"),
                    value: "P1M",
                },
                {
                    name: this.$t("exportFobs.fobsFilterThreeMonths"),
                    value: "P3M",
                },
                {
                    name: this.$t("exportFobs.fobsFilterSixMonths"),
                    value: "P6M",
                },
                {
                    name: this.$t("exportFobs.fobsFilterOneYear"),
                    value: "P1Y",
                },
            ],
            residenceType: this.$store.getters["account/getType"],
            fobsNumber: 0,
            minFobNumber: 0,
            maxFobNumber: 0,
            period: null,
            search: null,
            page: 1,
            pagesCount: 1,
            limit: 10,
        }
    },
    async mounted() {
        await this.load()
    },
    methods: {
        async load() {
            this.loading = true

            await this.getBreadcrumb()
            await this.getFobs()

            this.loading = false
        },
        async getBreadcrumb() {
            if (this.$route.name === "ExportFobsResidence") {
                this.idRes = this.$route.params.id
                await getResidenceSummaryById(this.idRes)
                    .then((response) => {
                        this.path = {
                            residence: {
                                name: response.data.path.residence.name,
                                id: response.data.path.residence.id,
                            },
                            noRouter: {
                                name: this.$t("exportFobs.fobsTitle"),
                            },
                        }
                    })
                    .catch((error) => {
                        console.log(error)
                    })
            } else if (this.$route.name === "ExportFobsStairwell") {
                await getStairwellSummaryById(this.$route.params.id).then(
                    (response) => {
                        this.idRes = response.data.path.residence.id
                        this.path = {
                            residence: {
                                name: response.data.path.residence.name,
                                id: response.data.path.residence.id,
                            },
                            cage: {
                                name: response.data.path.cage.name,
                                id: response.data.path.cage.id,
                            },
                            noRouter: {
                                name: this.$t("exportFobs.fobsTitle"),
                            },
                        }
                    }
                )
            }
        },

        determinateFobRange(total, pages, page, limit) {
            if (total === 0) {
                this.maxFobNumber = 0
                this.minFobNumber = 0
            } else {
                if (page === pages) {
                    this.maxFobNumber = total
                    this.minFobNumber = limit * pages - limit + 1
                } else {
                    this.maxFobNumber = limit * page

                    if (page > 1) {
                        this.minFobNumber = this.maxFobNumber - limit + 1
                    } else {
                        this.minFobNumber = 1
                    }
                }
            }
        },

        async getFobs() {
            this.noData = false
            this.noDataSearch = false
            this.loadingArray = true
            this.fobsObjectResidenceComplete.content = []
            this.fobsObjectResidenceBasic.content = []
            this.fobsObjectStairwell.content = []

            let params = {}

            if (this.$route.name === "ExportFobsResidence") {
                params = {
                    residence_id: this.path.residence.id,
                    q: this.search,
                    period: this.period,
                    limit: this.limit,
                    page: this.page,
                }
            } else if (this.$route.name === "ExportFobsStairwell") {
                params = {
                    residence_id: this.path.residence.id,
                    cage_id: this.path.cage.id,
                    q: this.search,
                    period: this.period,
                    limit: this.limit,
                    page: this.page,
                }
            }
            await getUsedAndUnusedFobs(params).then((response) => {
                if (response.message !== "API_RESTRICTED") {
                    //Format response to match the figma model

                    if (response.data.list.length > 0) {
                        // Format response for complete or simplified residence
                        if (
                            this.residenceType === 0 &&
                            this.$route.name === "ExportFobsResidence"
                        ) {
                            for (
                                let i = 0;
                                i < response.data.list.length;
                                i++
                            ) {
                                const newObject = {
                                    building:
                                        response.data.list[i].building_name,
                                    cage: response.data.list[i].cage_name,
                                    apart: [
                                        {
                                            value: response.data.list[i]
                                                .appart_name,
                                            option: {
                                                show: true,
                                                action: "seeMore",
                                                icon: { class: "fa fa-eye" },
                                                id: response.data.list[i]
                                                    .appart_id,
                                            },
                                        },
                                    ],
                                    serial: response.data.list[i].serialgrave,
                                    last_use: response.data.list[i].mod_at,
                                    option: {
                                        show: true,
                                        action: "delete",
                                        icon: {
                                            class: "fa fa-trash",
                                            style: "display: flex; align-items: center; font-size: 18px;",
                                        },
                                        id: response.data.list[i].fob_id,
                                    },
                                }

                                if (response.data.list[i].kibolt === "1") {
                                    newObject.option.show = false
                                }

                                this.fobsObjectResidenceComplete.content.push(
                                    newObject
                                )
                            }
                        }
                        // Format response for basic residence
                        else if (
                            this.residenceType === 2 &&
                            this.$route.name === "ExportFobsResidence"
                        ) {
                            for (
                                let i = 0;
                                i < response.data.list.length;
                                i++
                            ) {
                                const newObject = {
                                    serial: response.data.list[i].serialgrave,
                                    last_use: response.data.list[i].mod_at,
                                    option: {
                                        show: true,
                                        action: "delete",
                                        icon: {
                                            class: "fa fa-trash",
                                            style: "display: flex; align-items: center; font-size: 18px;",
                                        },
                                        id: response.data.list[i].fob_id,
                                    },
                                }
                                if (response.data.list[i].kibolt === "1") {
                                    newObject.option.show = false
                                }
                                this.fobsObjectResidenceBasic.content.push(
                                    newObject
                                )
                            }
                        }
                        // Format response for stairwell
                        else if (
                            this.$route.name === "ExportFobsStairwell" ||
                            (this.residenceType === 1 &&
                                this.$route.name === "ExportFobsResidence")
                        ) {
                            for (
                                let i = 0;
                                i < response.data.list.length;
                                i++
                            ) {
                                const newObject = {
                                    apartment: [
                                        {
                                            value: response.data.list[i]
                                                .appart_name,
                                            option: {
                                                show: true,
                                                action: "seeMore",
                                                icon: { class: "fa fa-eye" },
                                                id: response.data.list[i]
                                                    .appart_id,
                                            },
                                        },
                                    ],
                                    serial: response.data.list[i].serialgrave,
                                    last_use: response.data.list[i].mod_at,
                                    option: {
                                        show: true,
                                        action: "delete",
                                        icon: {
                                            class: "fa fa-trash",
                                            style: "display: flex; align-items: center; font-size: 18px;",
                                        },
                                        id: response.data.list[i].fob_id,
                                    },
                                }
                                if (response.data.list[i].kibolt === "1") {
                                    newObject.option.show = false
                                }
                                this.fobsObjectStairwell.content.push(newObject)
                            }
                        }
                    } else if (
                        !response.data.list.length &&
                        (this.search || this.period)
                    ) {
                        this.noDataSearch = true
                    } else if (
                        !response.data.list.length &&
                        !this.search &&
                        !this.period
                    ) {
                        this.noData = true
                    }
                    this.fobsNumber = parseInt(response.data._count)
                    this.pagesCount = parseInt(response.data._pages)
                    this.page = parseInt(response.data._page)

                    //calculate the range of items displayed
                    this.determinateFobRange(
                        parseInt(response.data._count),
                        this.pagesCount,
                        this.page,
                        this.limit
                    )
                    this.loadingArray = false
                } else {
                    this.loadingArray = false
                    this.$router.push({
                        path: "/error/401",
                    })
                }
            })
        },

        setPage(page) {
            this.page = page
            this.getFobs()
        },

        setFilter(value) {
            this.period = value
            this.page = 1
            this.getFobs()
        },

        setSearch(value) {
            this.search = value
            this.page = 1
            this.getFobs()
        },

        seeMore(id) {
            this.$router.push({ name: "Apartment", params: { id: id } })
        },

        deleteItem(id) {
            this.openModal("Validation", {
                question: this.$t("exportFobs.fobsDelete"),
                valid: () => {
                    deleteFob(id).then((response) => {
                        this.page = 1
                        this.getFobs()
                    })
                },
            })
        },

        exportData() {
            let params = {}
            if (this.$route.name === "ExportFobsResidence") {
                params = {
                    residence_id: this.idRes,
                    q: this.search,
                    period: this.period,
                    limit: this.limit,
                    page: this.page,
                }
            } else if (this.$route.name === "ExportFobsStairwell") {
                params = {
                    residence_id: this.idRes,
                    cage_id: this.path.cage.id,
                    q: this.search,
                    period: this.period,
                    limit: this.limit,
                    page: this.page,
                }
            }

            exportFobs(params).then((response) => {
                const date = new Date()
                // format date to have DD_MM_YYYY
                const day = date.getDate().toString().padStart(2, "0")
                const month = (date.getMonth() + 1).toString().padStart(2, "0")
                const year = date.getFullYear()

                const blob = new Blob([response], {
                    type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
                })

                const url = URL.createObjectURL(blob)

                const link = document.createElement("a")
                link.href = url
                link.download =
                    "fob_export_" + day + "_" + month + "_" + year + ".xlsx"
                document.body.appendChild(link)

                link.click()

                URL.revokeObjectURL(url)
                document.body.removeChild(link)
            })
        },

        whichContent() {
            if (
                this.$route.name === "ExportFobsResidence" &&
                this.residenceType === 0
            ) {
                return this.fobsObjectResidenceComplete
            } else if (
                this.$route.name === "ExportFobsResidence" &&
                this.residenceType === 2
            ) {
                return this.fobsObjectResidenceBasic
            } else if (
                this.$route.name === "ExportFobsStairwell" ||
                (this.residenceType === 1 &&
                    this.$route.name === "ExportFobsResidence")
            ) {
                return this.fobsObjectStairwell
            }
        },
    },
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/global.scss";
@import "@/assets/scss/details.scss";

.main-container {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: 1.5em;
    padding: 2em 4em;
    overflow: auto;

    @media all and (max-width: 1024px) {
        padding: 2em 1em;
    }

    .header-container {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;

        @media all and (max-width: 1024px) {
            flex-direction: column;
            justify-content: start;
            align-items: start;
            gap: 1.5em;
        }

        .left-part-header {
            width: 100%;
            display: flex;
            flex-direction: column;
            gap: 1em;

            h1 {
                font-family: $font-avenir-black;
                margin: 0 !important;
            }

            .fobs-number {
                font-size: $big;
                margin: 0 !important;
            }
        }

        .right-part-header {
            display: flex;
            justify-content: end;
            width: 100%;

            @media all and (max-width: 1024px) {
                justify-content: start;
            }
            .search {
                min-width: 300px;
                width: 90%;
                max-width: 600px;

                @media screen and (max-width: 1024px) {
                    width: 100%;
                    max-width: 500px;
                    min-width: unset;
                }
            }
        }
    }

    .filter-export-container {
        display: flex;
        align-items: center;
        justify-content: space-between;

        @media all and (max-width: 768px) {
            flex-direction: column;
            justify-content: start;
            align-items: start;
            gap: 1em;
        }

        .filters-wrapper {
            display: flex;
            align-items: center;
            gap: 0.5em;

            @media all and (max-width: 768px) {
                flex-direction: column;
                justify-content: start;
                align-items: start;
            }

            p {
                font-family: $font-avenir-black;
                color: $grey-darker;
                margin: 0 !important;

                @media all and (max-width: 1024px) {
                    font-family: $font-avenir-medium;
                }
            }

            .filters {
                width: 15em;
            }
        }

        .export-button {
            display: flex;
            gap: 0.5em;
            align-items: center;
            justify-content: center;
            min-width: 150px;
        }
    }

    .body-container {
        flex: auto;

        .no-data {
            width: 100%;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;

            p {
                font-family: $font_avenir_heavy;
                color: $pink;
            }
        }
    }

    .footer-container {
        flex: 1;
        display: flex;
        align-items: end;
        height: max-content;
    }
}

.loader-wrapper {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}
</style>
